<template>
  <div class="anaWrap">
    <section class="anaTitle"><div>人体成分8项检测分析</div></section>
    <section class="userTable">
      <div class="userTableRow">
        <div class="tableKey tableKeyFirst">区域门店</div>

        <div class="tableValue" :style="{fontSize:bodyComposition.jbxx.md.length >5 ? '12px' : '20px'}">{{bodyComposition.jbxx.md}}</div>
        <div class="tableKey">姓名</div>
        <div class="tableValue">{{bodyComposition.jbxx.name}}</div>
        <div class="tableKey">性别</div>
        <div class="tableValue">{{bodyComposition.jbxx.sex_show}}</div>
        <div class="tableKey">年龄</div>
        <div class="tableValue">{{bodyComposition.jbxx.age}}</div>
        <div class="tableKey">身高</div>
        <div class="tableValue">{{bodyComposition.jbxx.sg}}cm</div>

      </div>
      <div class="userTableSick">
        <div class="tableSickKey">疾病</div>
        <div class="tableSickValue">
          {{bodyComposition.jbxx ? bodyComposition.jbxx.jb : ''}}
        </div>
      </div>
    </section>
    <section class="target">
      <div class="targetWrap">
        <div class="targetTitle">人体成分测量指标</div>
        <div class="targetTitleEng">
          Measurement index of human body composition
        </div>
        <div class="targetCtx">
          <div class="targetLeftTable">
            <div class="tHeard">
              <div class="tHeardItem first">检测指标</div>
              <div class="tHeardItem">测量值</div>
              <div class="tHeardItem">普通值</div>
              <div class="tHeardItem">普通下限</div>
              <div class="tHeardItem last">普通上限</div>
            </div>
            <div class="tBody">
              <div class="tBodyRow" v-for="(item) in  bodyComposition.jczb" :key="item.name">
                <div class="tBodyRowItem first">{{item.name}}</div>
                <div class="tBodyRowItem">{{item.xz}}</div>
                <div class="tBodyRowItem">{{item.bzz}}</div>
                <div class="tBodyRowItem">{{item.xx}}</div>
                <div class="tBodyRowItem last">{{item.sx}}</div>
              </div>
            </div>
          </div>
          <div class="targetRightTable">
            <div class="tHeard">
              <div class="tHeardItem first" 
                v-for="(item,index) in targetArray" 
                :key='index'
                :style="{'color':item.color}"
                :class="{'first':item.isFirst,last:item.isLast}"
              >{{item.name}}</div>
            </div>
            <div class="tBodyRow" v-for="(item,index) in level" :key='index'>
              <div class="progress" v-if="item != -1">
                <div class="progressCtx" :style="{'width':(item*20 + 17 )+'%',backgroundColor:(item === 0 || item === 4) ? '#FE6572':'#00A220' }"></div>
              </div>
              <div class="tBodyRowItem" 
                v-for="(numbLevel,index1) in levelNumer" 
                :key='index1'
              ></div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="comment">
      <div class="commentWrap">
        <div class="commentTitle">关键指标评价</div>
        <div class="commentTitleEng">Degree judgment</div>
        <div class="commentHr">
          <div class="commentHrCtx"></div>
        </div>
        <div class="commentCtx">
          <div class="commentTable">
            <div class="tableRow" v-for="(item,index) in bodyComposition.zbpj" :key='index' :class="{noBorder:bodyComposition.zbpj && bodyComposition.zbpj.length == index+1}" >
              <div class="tableRowKey">{{item.name}}</div>
              <div class="tableRowValue">
               {{ item.pj}}
              </div>
            </div>
          </div>
          <div class="bodyImg">
            <img src="@/assets/img/body.jpg" alt="">
          </div>
        </div>
      </div>
    </section>
    <section class="expertSuggest">
      <div class="expertTip">专家咨询:</div>
      <textarea class="suggestCtx" rows="1" v-model="suggestValue"></textarea>
    </section>
    <section class="time">
      <div class="timeCtx">
        <div class="timeItem">数据日期：{{bodyComposition.sjrq}}</div>
        <div class="timeItem">报告日期：{{nowDate}}</div>
        <div class="timeItem">检测师：{{userInfo.name}}</div>
        <div class="timeItem">咨询师：{{userInfo.name}}</div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { foramtDate } from '@/filters/filters';
export default {
	name: '',
	props: {
		bodyComposition: {
			type: Object,
			default: () => {
				return {};
			},
		},
	},
	data() {
		return {
			targetArray: [
				{
					name: '低',
					color: 'rgb(247, 0, 0)',
					isFirst: true,
				},
				{
					name: '偏低',
					color: 'rgb(35, 123, 42)',
				},
				{
					name: '标准',
					color: 'rgb(0, 89, 7)',
				},
				{
					name: '偏高',
					color: 'rgb(35, 123, 42)',
				},
				{
					name: '高',
					color: 'rgb(247, 0, 0)',
					isLast: true,
				},
			],
			level: [],
			levelNumer: [
				{ num: 0, color: 'rgb(247, 0, 0)' },
				{ num: 1, color: 'rgb(35, 123, 42)' },
				{ num: 2, color: 'rgb(0, 89, 7)' },
				{ num: 3, color: 'rgb(35, 123, 42)' },
				{ num: 4, color: 'rgb(247, 0, 0)' },
			],
			nowDate: foramtDate(new Date()),
			suggestValue: '',
		};
	},
	mounted() {
		if (this.bodyComposition.jczb && this.bodyComposition.jczb.length > 0) {
			this.level = this.bodyComposition.jczb.map((item) => {
				return item.jb;
			});
		}
	},
	computed: {
		...mapGetters({ userInfo: 'userInfo' }),
	},
	watch: {
		bodyComposition: {
			handler(newValue) {
				console.log(newValue);
				if (newValue.jczb && newValue.jczb.length > 0) {
					this.level = newValue.jczb.map((item) => {
						return item.jb;
					});
				}
			},
			deep: true,
		},
	},
	methods: {},
	components: {},
};
</script>
<style scoped lang='less'>
.anaWrap {
  padding-bottom: 20px;
}
.noBorder{
  border-bottom:0 !important;
}
.anaTitle {
  padding: 0 20px;
  color: #2089bb;
  font-size: 26px;
  font-weight: bolder;
  margin-bottom: 20px;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 70px;
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
  }
}
.userTable {
  padding: 0 20px;
  margin-bottom: 30px;
  .userTableRow {
    display: flex;
    border-bottom: 1px solid #eaeaea;
    border-top: 1px solid #eaeaea;
    .tableKey {
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      display: flex;
      flex: 1;
      font-size: 20px;
      color: #266dd9;
      border-right: 1px solid #eaeaea;
    }
    .tableKeyFirst {
      border-left: 1px solid #eaeaea;
    }
    .tableValue {
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      font-size: 20px;
      color: #125fa4;
      border-right: 1px solid #eaeaea;
    }
  }
  .userTableSick {
    border-bottom: 1px solid #eaeaea;
    border-left: 1px solid #eaeaea;
    border-right: 1px solid #eaeaea;
    min-height: 60px;
    display: flex;
    font-size: 20px;
    color: #266dd9;
    .tableSickKey {
      width: calc(100% / 10);
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #eaeaea;
      flex-shrink: 0;
    }
    .tableSickValue {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #125fa4;
      padding-left: 10px;
    }
  }
}
.target {
  margin-bottom: 20px;
  .targetWrap {
    width: 100%;
    padding-bottom: 20px;
    background-color: #fff;
    .targetTitle {
      padding: 15px 20px 10px 20px;
      width: 100%;
      font-size: 22px;
      color: #288ca8;
      font-weight: bolder;
    }
    .targetTitleEng {
      padding: 0 20px 0 20px;
      width: 100%;
      font-size: 22px;
      color: #288ca8;
      margin-bottom: 20px;
      font-weight: bolder;
    }
    .targetCtx {
      display: flex;
      width: 100%;
      .targetLeftTable {
        width: 60%;
        padding: 0 20px;
        .tHeard {
          border: 1px solid #eaeaea;
          height: 60px;
          display: flex;
          font-size: 20px;
          color: #288ca8;
          font-weight: bolder;
          .tHeardItem {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1;
            border-right: 1px solid #eaeaea;
          }
          .first {
            flex: 1.5;
          }
          .last {
            border-right: 0;
          }
        }
        .tBody {
          .tBodyRow {
            display: flex;
            height: 45px;
            border: 1px solid #eaeaea;
            border-top: 0;
            .tBodyRowItem {
              display: flex;
              flex: 1;
              justify-content: center;
              align-items: center;
              border-right: 1px solid #eaeaea;
              font-size: 18px;
              font-weight: bolder;
            }
            .first {
              background-color: #f9f9f9;
              font-weight: bolder;
              font-size: 14px;
              color: #101010;
              flex: 1.5;
            }
            .last {
              border-right: 0;
            }
          }
        }
      }
      .targetRightTable {
        width: 38%;
        .tHeard {
          border: 1px solid #eaeaea;
          height: 60px;
          display: flex;
          font-size: 20px;
          color: #288ca8;
          font-weight: bolder;
          .tHeardItem {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1;
            border-right: 1px solid #eaeaea;
          }
        }
        .tBodyRow {
          display: flex;
          min-height: 45px;
          border: 1px solid #eaeaea;
          border-top: 0;
          position: relative;
          .progress{
            top: 20px;
            position: absolute;
            padding: 0 10px;
            width: calc(100% - 20px);
            .progressCtx{
              height: 10px;
              border-radius: 10px;
            }
          }
          .tBodyRowItem {
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: center;
            border-right: 1px solid #eaeaea;
            font-size: 18px;
            box-sizing: border-box;
          }
          .first {
            background-color: #f9f9f9;
            font-weight: bolder;
            font-size: 18px;
            color: #101010;
          }
          .last {
            border-right: 0;
          }
        }
      }
    }
  }
}
.comment {
  padding: 0 20px;
  margin-bottom: 20px;
  .commentWrap {
    width: 100%;
    // box-shadow: 0 0 10px #eaeaea;
    padding-bottom: 20px;
    background-color: #fff;
  }
  .commentTitle {
    padding: 0px 0px 10px 0px;
    width: 100%;
    font-size: 22px;
    color: #288ca8;
    font-weight: bolder;
  }
  .commentTitleEng {
    width: 100%;
    font-size: 22px;
    color: #288ca8;
    margin-bottom: 20px;
    font-weight: bolder;
  }
  .commentHr {
    margin-bottom: 20px;
    .commentHrCtx {
      width: 100%;
      height: 2px;
      background-color: #eaeaea;
    }
  }
  .commentCtx {
    display: flex;
    .commentTable {
      width: 76%;
      border: 1px solid #eaeaea;
      .tableRow {
        width: 100%;
        min-height: 40px;
        display: flex;
        font-weight: bolder;
        border-bottom: 1px solid #eaeaea;
        .tableRowKey {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 19px;
          width: 20%;
          border-right: 1px solid #eaeaea;
        }
        .tableRowValue {
          width: 80%;
          font-size: 19px;
          display: flex;
          padding-left: 20px;
          justify-content: flex-start;
          align-items: center;
        }
      }
    }
    .bodyImg {
      width: 24%;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
}
.expertSuggest {
  padding: 0 20px;
  .expertTip {
    width: 100%;
    height: 60px;
    line-height: 60px;
    font-size: 20px;
    color: #016682;
    font-weight: bolder;
  }
  .suggestCtx {
    color: #09468c;
    font-size: 17px;
    width: 100%;
  }
}
.time {
  padding: 20px;
  .timeCtx {
    display: flex;
    font-size: 18px;
    width: 100%;
    height: 40px;
    .timeItem {
      justify-content: center;
      display: flex;
      height: 100%;
      flex: 2;
    }
  }
}
</style>
