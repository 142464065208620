<template>
  <div class="healthChartWarp">
    <div class="sun_title">健康衰退曲线评价</div>
    <div class="border_line"></div>
    <div class="sun_banner">
      <div class="sun_banner_content">
        <img src="@/assets/img/healthChartPic.png" alt="" />
      </div>
    </div>
    <div class="decline">
      <span class="decline_theory">衰退理论</span>
    </div>
    <div class="decline_descript" v-html="declineStr"></div>
    <div class="border_line"></div>
    <div class="cahrt_title">您的衰退程度曲线：</div>
    <div class="sun_banner">
      <div class="sun_banner_content1">
        <img :src="getImage()" />
      </div>
    </div>
    <div class="charts_descript">
      <div class="descript_content">
        {{ healthChratComment }}
      </div>
    </div>
  </div>
</template>

<script>
import bus from './eventBus';
export default {
	name: '',
	data() {
		return {
			declineStr:
				"<span style='font-size: 30px;font-weight: bolder;'>衰退</span>是指由于多种因素，使机体的生物节律与正常的生物节律出现差异，导致机体易损性增加，从而引起,机体功能异常下降、衰弱、退化的现象。科学研究发现人体的器官功能衰退与年龄和生活方式有着直接的关系，影响生命质量的真正敌人是机体衰退。人体在没有相应措施的情况下,成熟年龄后各重要系统的功能每年以0.5-1％的速率递减；60岁以后每年以1-2％的速率递减。免疫系统、肺活量、各脏器功能、肾血流量、血管韧性、肌肉力量、骨骼耐力无一不在逐渐衰退的过程中。",
		};
	},
	props: {
		chartNumber: Number,
		healthChratComment: String,
	},
	created() {},
	mounted() {},
	computed: {},
	watch: {},
	methods: {
		getImage() {
			return new URL(`/src/assets/img/chart${this.chartNumber}.png`, import.meta.url).href;
		},
	},
	components: {},
};
</script>

<style lang="less" scoped>
.healthChartWarp {
  padding-top: 15px;

  .sun_title {
    font-size: 34px;
    margin-top: 10px;
    color: rgb(29, 107, 198);
    line-height: 40px;
    text-align: center;
    position: relative;

    .sun_store {
      position: absolute;
      right: 30px;
      top: 10px;
      font-size: 22px;
    }
  }

  .border_line {
    width: 100%;
    height: 2px;
    background-color: rgb(29, 107, 198);
    margin: 10px 0px;
  }

  .sun_banner {
    width: 100%;
    height: 360px;
    justify-content: center;
    display: flex;

    .sun_banner_content {
      width: 90%;
      height: 100%;
      margin-top: 20px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .sun_banner_content1 {
      width: 90%;
      height: 360px;
      margin-top: 20px;

      img {
        width: 100%;
        height: 360px;
      }
    }
  }

  .decline {
    width: 100%;
    height: 60px;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    .decline_theory {
      padding: 10px 30px;
      font-size: 26px;
      font-weight: bolder;
      background-color: rgb(13, 105, 198);
      color: #fff;
    }
  }

  .decline_descript {
    padding: 10px 40px;
    font-size: 24px;
    color: rgb(12, 63, 142);
  }

  .cahrt_title {
    font-size: 24px;
    color: rgb(3, 95, 162);
    display: flex;
    justify-content: center;
    font-weight: bolder;
  }

  .charts_descript {
    padding: 20px;
    font-size: 26px;

    .descript_content {
      border: 1px solid rgb(46, 127, 221);
      padding: 20px;
      color: rgb(29, 107, 198);
    }
  }
}</style>
