import xhr from './xhr/';
// 风险评估
class riskServer {
	getBodyComposition(params) {
		return xhr({
			method: 'post',
			url: '/fxpg/rtcf/get',
			params,
		});
	}
	healthRecords(params) {
		return xhr({
			method: 'post',
			url: '/fxpg/jkda',
			params,
		});
	}
	getReadData(params) {
		return xhr({
			method: 'post',
			url: '/fxpg/sjzb',
			params,
		});
	}
	getQuestData(params) {
		return xhr({
			method: 'post',
			url: '/question/jkzy',
			params,
		});
	}
	getBloodData(params) {
		return xhr({
			method: 'post',
			url: '/fxpg/sjxq',
			params,
		});
	}
}
// 实例化后导出，全局单例
export default new riskServer();
