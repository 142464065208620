<template>
	<div class="sugar-container">
		<layout nameCh="血糖分析报告" nameEn="Blood glucose management">
			<div class="sr-header">
				<div class="sr-header-card">
					<span class="sr-header-date">测试日期：{{ fiveSugarData.testDate }}</span>
					<div class="sr-header-select">
						<img src="@/assets/img/manage/canyin.png" alt="img">
						<span>测试饮食：</span>
						<span>{{ fiveSugarData.testDiet }}</span>
					</div>
				</div>
				<div>
					<el-table :data="sugarDataTable" border style="width: 100%">
						<el-table-column prop="empty" label="空腹" align="center">
							<template slot-scope="scope">
								<div class="sr-cell">
									<span>{{ scope.row.empty }}mmol/L</span>
									<i :class="{equal: scope.row.empty >= 3.9 && scope.row.empty <= 6.1, up: scope.row.empty > 6.1, down: scope.row.empty < 3.9}"
									v-if="scope.row.empty"></i>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="halfhour" label="餐后0.5h" align="center">
							<template slot-scope="scope">
								<div class="sr-cell">
									<span>{{ scope.row.halfhour }}mmol/L</span>
									<i :class="{equal: scope.row.halfhour >= 5.1 && scope.row.halfhour <= 7, up: scope.row.halfhour > 7, down: scope.row.halfhour < 5.1}"
									v-if="scope.row.halfhour"></i>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="hour" label="餐后1h" align="center">
							<template slot-scope="scope">
								<div class="sr-cell">
									<span>{{ scope.row.hour }}mmol/L</span>
									<i :class="{equal: scope.row.hour >= 6.7 && scope.row.hour <= 9.4, up: scope.row.hour > 9.4, down: scope.row.hour < 6.7}"
									v-if="scope.row.hour"></i>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="twohour" label="餐后2h" align="center">
							<template slot-scope="scope">
								<div class="sr-cell">
									<span>{{ scope.row.twohour }}mmol/L</span>
									<i :class="{equal: scope.row.twohour >= 3.9 && scope.row.twohour <= 7.8, up: scope.row.twohour > 7.8, down: scope.row.twohour < 3.9}"
									v-if="scope.row.twohour"></i>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="threehour" label="餐后3h" align="center">
							<template slot-scope="scope">
								<div class="sr-cell">
									<span>{{ scope.row.threehour }}mmol/L</span>
									<i :class="{equal: scope.row.threehour >= 3.9 && scope.row.threehour <= 6.1, up: scope.row.threehour > 6.1, down: scope.row.threehour < 3.9}"
									v-if="scope.row.threehour"></i>
								</div>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
			<!-- 要素服用情况 -->
			<div class="sr-panel">
				<div class="sr-panel-header">
					<div class="sph-title">
						<img src="@/assets/img/manage/yaoxiang.png" alt="yaoxiang">
						<span>要素服用情况</span>
					</div>
				</div>
				<div class="sr-panel-content">
					<el-table :data="fiveSugarData.elements" border empty-text="测试时间段内未用药" style="width: 100%">
						<el-table-column prop="time" label="用药时间" align="center">
							<template slot-scope="scope">
								<span>{{ scope.row.time ? timeList[scope.row.time - 1].label : '' }}</span>
							</template>
						</el-table-column>
						<el-table-column prop="medicines" label="药名" align="center">
							<template slot-scope="scope">
								<span>{{ scope.row.medicines ? medicinesList[scope.row.medicines - 1].label : '' }}</span>
							</template>
						</el-table-column>
						<el-table-column prop="dose" label="剂量" align="center"></el-table-column>
						<el-table-column prop="unit" label="单位" align="center">
							<template slot-scope="scope">
								<span>{{ unitList[scope.row.unit - 1].label }}</span>
							</template>
						</el-table-column>
						<el-table-column prop="way" label="用药方式" align="center">
							<template slot-scope="scope">
								<span>{{ scope.row.way ? wayList[scope.row.way - 1].label : '' }}</span>
							</template>
						</el-table-column>
						<el-table-column prop="remark" label="备注" align="center"></el-table-column>
					</el-table>
				</div>
			</div>
			<!-- 运动情况 -->
			<div class="sr-panel">
				<div class="sr-panel-header">
					<div class="sph-title">
						<img src="@/assets/img/manage/paobu.png" alt="paobu">
						<span>测量前运动情况</span>
					</div>
				</div>
				<div class="sr-panel-content">
					<el-table :data="fiveSugarData.sports" border empty-text="测试时间段内无运动" style="width: 100%">
						<el-table-column prop="time" label="测试节点" align="center"></el-table-column>
						<el-table-column prop="exercise" label="运动强度" align="center">
							<template slot-scope="scope">
								<span>{{ scope.row.exercise ? exerciseList[scope.row.exercise - 1].label : '' }}</span>
							</template>
						</el-table-column>
						<el-table-column prop="duration" label="时长" align="center">
							<template slot-scope="scope">
								<span>{{ scope.row.duration }}min</span>
							</template>
						</el-table-column>
						<el-table-column prop="content" label="运动内容" align="center"></el-table-column>
					</el-table>
				</div>
			</div>
			<!-- OGTT胰岛功能曲线 -->
			<div class="sr-panel">
				<div class="sr-panel-header">
					<div class="sph-title">
						<img src="@/assets/img/manage/fund.png" alt="fund" style="width: 48px;">
						<div class="sph-title-wrap">
							<h3 class="title-ch">OGTT胰岛功能分析</h3>
							<p class="title-en">OGTT islet function curve</p>
						</div>
					</div>
				</div>
				<div class="sr-panel-content">
					<line-chart :sugar-data="fiveSugarData.fivesugar"></line-chart>
				</div>
			</div>
			<!-- 血糖测试分析与指导 -->
			<div class="sr-panel">
				<div class="sr-panel-header">
					<div class="sph-title">
						<img src="@/assets/img/manage/baogaogongdan.png" alt="baogaogongdan" style="width: 48px;">
						<div class="sph-title-wrap">
							<h3 class="title-ch">血糖测试分析与指导</h3>
							<p class="title-en">Blood glucose management report</p>
						</div>
					</div>
					<div class="sign-wrap">
						<span>检测师: {{ fiveSugarData.detecter }}</span>
					</div>
				</div>
				<div class="sr-panel-content">
					<div class="textarea-content">
						<div class="textarea-wrap" v-html="fiveSugarData.xtwt"></div>
						<h2 class="rate-title">专家评价：</h2>
						<div class="textarea-card" v-html="fiveSugarData.rate"></div>
					</div>
				</div>
			</div>
		</layout>
	</div>
</template>

<script>
import Layout from './layout';
import lineChart from './lineChart';
export default {
	props: {
		fiveSugarData: {
			type: Object,
			default: () => {
				return {};
			},
		},
	},
	components: {
		Layout,
		lineChart,
	},
	data() {
		return {
			sugarDataTable: [],
			timeList: [
				{
					label: '空腹',
					value: 1,
					disabled: false,
				},
				{
					label: '餐后0.5小时',
					value: 2,
					disabled: false,
				},
				{
					label: '餐后1小时',
					value: 3,
					disabled: false,
				},
				{
					label: '餐后2小时',
					value: 4,
					disabled: false,
				},
				{
					label: '餐后3小时',
					value: 5,
					disabled: false,
				},
			],
			medicinesList: [
				{
					label: '降糖药',
					value: 1,
				},
				{
					label: '其他药',
					value: 2,
				},
				{
					label: '保健品',
					value: 3,
				},
				{
					label: '近体物质',
					value: 4,
				},
			],
			unitList: [
				{
					label: 'U',
					value: 1,
				},
				{
					label: 'mg',
					value: 2,
				},
				{
					label: '片',
					value: 3,
				},
				{
					label: '粒',
					value: 4,
				},
				{
					label: '袋',
					value: 5,
				},
			],
			wayList: [
				{
					label: '口服',
					value: 1,
				},
				{
					label: '注射',
					value: 2,
				},
				{
					label: '其他',
					value: 3,
				},
			],
			exerciseList: [
				{
					label: '低等强度:散步、做家务、外出步行等',
					value: 1,
				},
				{
					label:
						'中等强度:慢跑、快走、骑自行车、乒乓球、羽毛球、广场舞、门球、按摩操、太极拳、器械训练等',
					value: 2,
				},
				{
					label: '不运动，静坐为主',
					value: 3,
				},
				{
					label: '高等强度:跳绳、快跑、游泳、网球、健身操、搬运重物',
					value: 4,
				},
			],
		};
	},
	mounted() {
		if (this.fiveSugarData.fivesugar && this.fiveSugarData.fivesugar.length) {
			this.sugarDataTable = [
				{
					empty: this.fiveSugarData.fivesugar[0],
					halfhour: this.fiveSugarData.fivesugar[1],
					hour: this.fiveSugarData.fivesugar[2],
					twohour: this.fiveSugarData.fivesugar[3],
					threehour: this.fiveSugarData.fivesugar[4],
				},
			];
		}
	},
};
</script>

<style lang="less" scoped>
	.sugar-container {
		color: #056add;
		font-size: 18px;

		.sr-header {
			.sr-header-card {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin: 10px 0;

				.sr-header-date,
				.sr-header-select {
					font-weight: 700;
				}

				.sr-header-time {
					display: flex;
					align-items: center;

					>img {
						width: 36px;
					}

					>span {
						margin-right: 8px;
						font-weight: 700;
						font-size: 18px;
					}
				}

				.sr-header-select {
					>img {
						width: 36px;
					}
				}
			}

			.ivu-select,
			.ivu-select-placeholder,
			.ivu-select-selected-value,
			.ivu-select-item {
				color: #056add;
			}
		}

		.sr-cell {
			display: flex;
			align-items: center;
			justify-content: center;
			color: #056add;

			.up,
			.down {
				width: 0;
				height: 0;
				border: 10px solid;
				margin-left: 8px;
			}

			.up {
				border-color: transparent transparent #ff4444;
				margin-top: -14px;
			}

			.down {
				border-color: #ff9743 transparent transparent;
				margin-top: 6px;
			}

			.equal {
				width: 20px;
				height: 3px;
				background-color: rgb(43, 172, 0);
				margin-left: 8px;
			}
		}

		.sr-panel {
			margin-top: 20px;

			.sr-panel-header {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 15px;

				.sph-title {
					font-weight: 700;
					display: flex;
					align-items: center;

					>img {
						width: 24px;
						margin-right: 8px;
					}
				}

				.sph-title-wrap {
					display: flex;
					flex-direction: column;
					justify-content: center;

					.title-ch {
						font-size: 18px;
						font-weight: 700;
					}

					.title-en {
						font-size: 14px;
						font-weight: 400;
					}
				}
			}

			.chart {
				width: 100%;
				height: 340px;
			}

			.sign-wrap {
				float: right;
				color: #005AB3;
				font-size: 16px;
				display: flex;
				align-items: flex-end;
			}

			.textarea-content {
				border: 1px solid #297ddf;
				padding: 10px;
			}

			.textarea-wrap {
				width: 100%;
				margin-bottom: 10px;
				color: #005AB3;
			}

			.textarea-card {
				width: 100%;
				min-height: 120px;
				color: #005AB3;
				border: 1px solid #297ddf;
				padding: 8px;
			}

			.rate-title {
				font-weight: 700;
				font-size: 18px;
				padding-bottom: 10px;
				padding-top: 10px;
			}
		}
		.save-wrap {
			margin-top: 20px;
			text-align: right;
		}
	}
</style>
