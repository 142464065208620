<template>
  <div class="peckb_wrap" v-if="peckbData.user_info">
    <div class="user-panel">
      <span>姓名：{{ peckbData.user_info.name }}</span>
      <span>性别：{{ peckbData.user_info.sex_name}}</span>
      <!-- <span>年龄：{{peckbData.user_info.age}}</span> -->
      <span>身高：{{ peckbData.user_info.sg }}cm</span>
      <span>体重：{{ peckbData.user_info.tz }}kg</span>
    </div>
    <div class="result-graph" ref="graph"></div>
    <table class="result-table">
      <tr>
        <th>{{ peckbData.dfqk["体质"] }}</th>
        <th>{{ peckbData.dfqk["观念"] }}</th>
        <th>{{ peckbData.dfqk["知识"] }}</th>
        <th>{{ peckbData.dfqk["环境"] }}</th>
        <th>{{ peckbData.dfqk["行为"] }}</th>
      </tr>
    </table>
    <div class="result-summary">
      <p class="result-summary-item">
        <span>同年龄、性别、身高等基础上的体质情况为</span>
        <span>{{ peckbData.jl["体质"] }}</span>
      </p>
      <p class="result-summary-item">
        <span>理解健康的重要性，愿意采取措施的程度为</span>
        <span>{{ peckbData.jl["观念"] }}</span>
      </p>
      <p class="result-summary-item">
        <span>了解正确的健康知识，知道误区的程度为</span>
        <span>{{ peckbData.jl["知识"] }}</span>
      </p>
      <p class="result-summary-item">
        <span>会使用各种提高效率的健康工具来改善健康环境的程度为</span>
        <span>{{ peckbData.jl["环境"] }}</span>
      </p>
      <p class="result-summary-item">
        <span>已经做到的行为符合标准、规避错误的程度为</span>
        <span>{{ peckbData.jl["行为"] }}</span>
      </p>
    </div>
    <div class="result-total">
      您的整体健康资源盘点（PCKEB）得分为<span>{{ peckbData.ztpj.df }}</span
      >，评级为<span>{{ peckbData.ztpj.pj }}</span
      >。
    </div>
    <div class="result-date">报告日期：{{ peckbData.txsj }}</div>
    <img src="@/assets/img/code.png" class="footer-img" />
  </div>
</template>

<script>
import echarts from 'echarts';
export default {
	name: '',
	props: {
		peckbData: {
			type: Object,
			default: () => {
				return {};
			},
		},
	},
	data() {
		return {
			chart: null,
		};
	},
	mounted() {
		if (this.peckbData.dfqk) {
			this.initChart(this.peckbData);
		}
	},
	computed: {
		currentDay() {
			let Y = new Date().getFullYear();
			let M = new Date().getMonth();
			let D = new Date().getDate();
			return `${Y}-${M}-${D}`;
		},
	},
	watch: {
		peckbData: {
			deep: true,
			handler(newV) {
				this.initChart(newV);
			},
		},
	},
	methods: {
		initChart(value) {
			let option = {
				radar: {
					shape: 'circle',
					axisName: {
						color: '#fff',
						backgroundColor: '#999',
						borderRadius: 3,
						padding: [3, 5],
					},
					indicator: Object.keys(value.dfqk).map((item) => ({
						name: item,
						max: 100,
					})),
				},
				series: [
					{
						type: 'radar',
						data: [
							{
								value: Object.values(value.dfqk),
								lineStyle: { color: '#2472fb' },
								label: { show: true, color: '#ff8864' },
								areaStyle: {
									color: '#BFDEFE',
								},
							},
						],
						label: {
							color: '#000',
							fontWeight: 700,
							fontSize: 16,
						},
					},
				],
			};
			this.chart = echarts.init(this.$refs.graph);
			this.chart.clear();
			this.chart.setOption(option);
		},
	},
	components: {},
};
</script>

<style scoped lang='less'>
.peckb_wrap {
  width: 100%;
  height: 1500px;
  background: url("@/assets/img/pckeb-bg.jpg") no-repeat 0 0 / cover;
  position: relative;
  .user-panel {
    padding-top: 140px;
    display: flex;
    justify-content: space-evenly;
    font-size: 22px;
  }
  .result-graph {
    width: 440px;
    height: 360px;
    margin: 20px auto 0;
  }
  .result-table {
    font-size: 36px;
    text-align: center;
    font-weight: 700;
    color: #2472fb;
    width: 650px;
    margin: 51px 0px 0px 235px;
    table-layout: fixed;
  }
  .result-summary {
    margin: 190px 0 0 387px;
    font-size: 20px;
    color: #84adff;
    width: 575px;
    & .result-summary-item {
      margin-bottom: 39px;
      display: flex;
      justify-content: space-between;
      & span:nth-child(2) {
        color: #2472fb;
        font-weight: 700;
      }
    }
  }
  .result-total {
    font-size: 20px;
    color: #84adff;
    margin: 130px 0 0 320px;
    & span {
      font-weight: 700;
      font-size: 32px;
      color: #2472fb;
      padding: 0 5px;
    }
  }
  .result-date {
    font-size: 20px;
    color: #84adff;
    margin: 20px 220px 0 0;
    text-align: right;
  }
  .button-bar {
    text-align: center;
    padding: 20px 0;
  }
  .footer-img {
    position: absolute;
    left: 10px;
    bottom: 10px;
    width: 100px;
  }
}
</style>
