<template>
	<div class="sun_content" id="sun_content">
		<!-- sun列表 -->
		<div v-if="reportList.length > 0">
			<sun-list @changePage='changePageList' :reportCurrentPage='reportCurrentPage' :reportList='reportList'
				:reportTotal='reportTotal' :reportData='reportData' />
		</div>
		<!-- 封面 -->
		<div class="compoentDom" v-if="coverFlag1">
			<div class="banner">
				<img src="@/assets/img/banner1.jpg" alt="">
			</div>
		</div>
		<div class="compoentDom" v-if="coverFlag2">
			<div class="banner">
				<img src="@/assets/img/banner2.jpg" alt="">
			</div>
		</div>
		<!-- 档案 -->
		<div class="compoentDom" v-if="healthFlag">
			<health :sickUserInfo="sickUserInfo" :nowSick="nowSick" :careSick="careSick" :family="family" />
		</div>
		<!-- 人体方案 -->
		<div class="compoentDom" v-if="bodyCompositionFlag">
			<analysis :bodyComposition='bodyComposition' />
		</div>
		<!-- 13+1 血压及血管风险管理 -->
		<div class="compoentDom" v-if="bloodPressureFlag">
			<pressure-one :pressure-data="bloodPressureData.pressureData"
				:pressure-summary="bloodPressureData.pressureSummary" />
			<pressure-two :pressure-data="bloodPressureData.pressureData" :result="bloodPressureData.reportResult" />
		</div>
		<!-- 5 点血糖管理 -->
		<div class="compoentDom" v-if="bloodSugarFlag">
			<sugar-report :five-sugar-data="bloodSugarData" ref="sugarReport"></sugar-report>
		</div>
		<!-- SUN生理系统评分总值 -->
		<div class="compoentDom" v-if="sunDataFlag">
			<div class="sun_title marginBottom">SUN生理系统评分总值</div>
			<div class="border_line"></div>
			<div class="title_tip flex">
				<div class="left flex">
					<img src="@/assets/img/fas fa-user-md.svg" />
				</div>
				<div class="right">报告结果</div>
			</div>
			<div class="pic_box flex">
				<div class="pic_content">
					<img src="@/assets/img/4481625627944_.pic_hd.jpg" alt />
					<div class="sun_tag_data" v-for="(data,index) in sunData" :key="index"
						:style="{'left':data.left,'top':data.top}">{{data.value}}%</div>
					<div class="sun_tag flex" v-for="(item,index) in sunTag" :key="item.txt"
						:style="{'left':item.left,'top':item.top}">
						<div class="sun_tag_img">
							<img :src="getImage(`sun${index + 1}.png`)" />
						</div>
						<div class="sun_tag_txt" :style="{'color':item.color}">{{item.txt}}</div>
						<div class="sun_tag_txt_eng" :style="{'color':item.color}">{{item.eng}}</div>
					</div>
				</div>
			</div>
			<div class="report_res flex">SUN评估风险</div>
			<div class="report_res_list">
				<div class="report_res_item" v-for="(item,index) in reportRes" :key="index">
					<span
						:style="{'color':reportResIndex[index].color}">{{reportResIndex[index].value}}</span>{{item.mc}}
					<span class="resTag" v-if="item.jd"
						:style="{'color':reportResIndex[index].color}">{{item.jd}}</span>
				</div>
				<div class="report_res_item">注:上述风险依据您提供的信息来判断,并不包含还没有发现的<span style="color:#f9c270">隐匿风险</span></div>
			</div>
			<div class="sun_title">SUN生理影响因素</div>
			<div class="border_line"></div>
			<div class="title_tip flex">
				<div class="left flex">
					<img src="@/assets/img/photo.png" />
				</div>
				<div class="right">生理影响因素</div>
			</div>
			<div class="pic_box_body flex">
				<div class="pic_content_body">
					<img src="@/assets/img/body1.png" alt />
					<div class="body_tag" v-for="(item,index) in bodyTag" v-show="item.isShow" :key="index"
						:style="{'left':item.left,'top':item.top,'background':item.color}">
						<span>{{renderTxt(item.txt)}}</span>
						<div class="body_tag_line" :class="{right_line :!item.isleft,left_line:item.isleft}"
							:style="{'background':item.color,'width':item.line_width}"></div>
					</div>
				</div>
			</div>
			<div class="report_res flex">报告结果</div>
			<div class="report_end_res">{{slyxys}}</div>
		</div>
		<!-- SUN生理系统风险评估魔方 -->
		<div class="compoentDom" v-if="sunDataFlag">
			<div class="sun_title">
				<span>SUN生理系统风险评估魔方</span>
				<span class="sun_store">门店:{{sickUserInfo.md}}</span>
			</div>
			<div v-for="(item,index) in pgmfArray1" :key="index">
				<div class="border_line system"></div>
				<div class="sub_script">{{item.name}}</div>
				<div class="sun_banner flex">
					<div class="sun_banner_content">
						<img :src="getImage(item.imgUrl)" />
					</div>
				</div>
				<div class="cube_res flex">
					<div class="cube_res_detail flex">
						<div class="res_tag flex">结果报告</div>
						<div class="res_table">
							<div class="res_table_heard flex">
								<div class="table_heard_item flex">Sickness</div>
								<div class="table_heard_item flex">Uniqueness</div>
								<div class="table_heard_item flex no_border">Norm</div>
							</div>
							<div class="res_table_heard flex">
								<div class="table_heard_item flex" :class="{'no_border':index1 === item.score.length-1}"
									v-for="(tableDate,index1) in item.score" :key="index1">{{tableDate}}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="cube_res_tip flex">
					<div class="cube_res_tip_ctx">{{item.bgjg}}</div>
				</div>
				<div class="cube_bottom" v-if="pgmfArray1.length-1 == index"></div>
			</div>
		</div>
		<!-- SUN生理系统风险权重排序 -->
		<div class="compoentDom" v-if="sunDataFlag">
			<div class="sun_title">
				<span>SUN生理系统风险权重排序</span>
				<span class="sun_store">门店:{{sickUserInfo.md}}</span>
			</div>
			<div v-for="(item,index) in pgmfArray2" :key="index">
				<div class="border_line system"></div>
				<div class="sub_script">{{item.name}}</div>
				<div class="sun_banner flex">
					<div class="sun_banner_content">
						<img :src="getImage(item.imgUrl)" />
					</div>
				</div>
				<div class="cube_res flex">
					<div class="cube_res_detail flex">
						<div class="res_tag flex">结果报告</div>
						<div class="res_table">
							<div class="res_table_heard flex">
								<div class="table_heard_item flex">Sickness</div>
								<div class="table_heard_item flex">Uniqueness</div>
								<div class="table_heard_item flex no_border">Norm</div>
							</div>
							<div class="res_table_heard flex">
								<div class="table_heard_item flex" :class="{'no_border':index1 === item.score.length-1}"
									v-for="(tableDate,index1) in item.score" :key="index1">{{tableDate}}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="cube_res_tip flex">
					<div class="cube_res_tip_ctx">{{item.bgjg}}</div>
				</div>
				<div class="cube_bottom" v-if="pgmfArray1.length-1 == index"></div>
			</div>
			<div class="border_line system" style="margin-top:0;margin-bottom:10px"></div>
			<div class="rank_title">生理影响因素</div>
			<div class="sun_banner height310 flex">
				<div class="sun_banner_content">
					<img src="@/assets/img/rank.png" alt />
					<div class="sun_banner_ranktxt" v-for="(item1,index1) in slyxysPx" :key="index1"
						:style="{'left':item1.left,'top':item1.top}">{{item1.value}}</div>
				</div>
			</div>
			<div class="rank_list flex">
				<div class="rank_item" v-for="(item,index) in slyxysSm" :key="index">
					<div class="rank_item_txt">{{index+1}}:{{item}}</div>
					<div class="index_container flex">
						<div class="index_center flex">{{index+1}}</div>
					</div>
				</div>
			</div>
		</div>
		<!-- SUN生理系统风险权重排序 -->
		<div class="compoentDom peckbBox" v-if="peckbDataFlag">
			<Peckb :peckbData='peckbData' />
		</div>
		<!-- 健康衰退曲线评价 -->
		<div class="compoentDom" v-if="chartFlag">
			<Health-chart :chartNumber='chartNumber' :healthChratComment='healthChratComment' />
		</div>
	</div>
</template>

<script type="text/ecmascript-6">
import reportSportsService from '@/services/reportSportsService';
import riskService from '@/services/riskService';
import memberService from '@/services/memberService';
import bus from './eventBus';
import Health from '../risk/risk_health';
import Analysis from '../risk/risk_analysis';
import Peckb from './peckb';
import HealthChart from './healthChart';
import SunList from './sunList';
import PressureOne from '../blood-pressure/pressure-1';
import PressureTwo from '../blood-pressure/pressure-2';
import SugarReport from '../blood-sugar/sugar-report1';
import { mapActions } from 'vuex';

export default {
	data() {
		return {
			reportRes: [],
			reportResIndex: [
				{
					color: '#ea5050',
					value: 'S',
				},
				{
					color: '#2fbfe2',
					value: 'U',
				},
				{
					color: '#23cf3b',
					value: 'N',
				},
			],
			rankPosition: [
				{
					left: ' 16.5vw',
					bottom: '20vh',
				},
				{
					left: ' 26vw',
					bottom: '23vh',
				},
				{
					left: ' 36vw',
					bottom: '19vh',
				},
			],
			sunTag: [
				{
					left: '60px',
					top: '0vh',
					color: '#DE514B',
					txt: '遗传信息',
					url: '/assets/img/sun2.png',
					eng: 'Uniqueness',
				},
				{
					left: '502px',
					top: '0px',
					color: '#32BEE3',
					txt: '疾病信息',
					url: '../../../assets/img/sun2.png',
					eng: 'Sickness',
				},
				{
					left: '552px',
					top: '300px',
					color: '#23CF3A',
					txt: '生理能力',
					url: '../../../assets/img/sun3.png',
					eng: 'Norm',
				},
			],
			sunData: [
				{
					value: '',
					left: '180px',
					top: '136px',
				},
				{
					value: '',
					left: '346px',
					top: '181px',
				},
				{
					value: '',
					left: '433px',
					top: '318px',
				},
			],
			slyxys: '', // 生理影响因素的报告内容
			pgmfArray1: [], // 评估魔方
			pgmfArray2: [], // 评估魔方
			slyxysSm: [], // 生理影响因素排序描述
			slyxysSmColor: ['#EA8480', '#EBB45F', '#DC4762'], // 生理影响因素排序描述
			slyxysPx: [
				{
					left: '185px',
					top: '161px',
					value: '',
				},
				{
					left: '360px',
					top: '141px',
					value: '',
				},
				{
					left: '520px',
					top: '168px',
					value: '',
				},
			], // 生理影响因素排序描述
			bodyTag: [
				{
					left: '43px',
					top: '20px',
					isleft: true,
					color: '#2762E1',
					txt: '脑膜炎',
					isShow: true,
					line_width: '230px',
				},
				{
					left: '453px',
					top: '89px',
					isleft: false,
					color: '#FFB467',
					txt: '心率过慢',
					isShow: true,
					line_width: '230px',
				},
				{
					left: '-110px',
					top: '144px',
					isleft: true,
					color: '#E40000',
					txt: '肾炎',
					isShow: false,
					line_width: '293px',
				},
				{
					left: '443px',
					top: '189px',
					isleft: false,
					color: '#00D2C1',
					txt: '前列腺增生',
					line_width: '230px',
					isShow: true,
				},
			],
			member_id: '2881',
			healthFlag: false,
			sunDataFlag: false,
			bodyCompositionFlag: false,
			bloodPressureFlag: false,
			bloodSugarFlag: false,
			sickUserInfo: {},
			bodyComposition: {},
			family: '',
			nowSick: '',
			careSick: '',
			peckbData: {},
			peckbDataFlag: false,
			doubleTip: false,
			chartNumber: 0,
			chartFlag: false,
			projectList: [],
			reportList: [],
			reportTotal: 1,
			reportCurrentPage: 1,
			reportData: {},
			start_time: '',
			end_time: '',
			sid: '',
			cid: '',
			uid: '',
			healthChratComment: '',
			coverFlag1: false,
			coverFlag2: false,
			bloodPressureData: {},
			bloodSugarData: {},
		};
	},
	mounted() {
		let readData = [];
		this.getReportList();
		bus.$on('getReportList', (params) => {
			this.start_time = params.start_time;
			this.end_time = params.end_time;
			this.sid = params.sid;
			this.cid = params.cid;
			this.pid = params.pid;
			this.getReportList();
		});
		bus.$on('changeUid', (v) => {
			this.initFlagData();
			readData = v.selectReadData;
			this.member_id = v.member_id;
		});
		bus.$on('saveReport', (projectList) => {
			this.initFlagData();
			this.projectList = projectList;

			if (projectList.indexOf('2021适生健康方案') >= 0) {
				this.coverFlag1 = true;
			}
			if (projectList.indexOf('风险评估方案') >= 0) {
				this.coverFlag2 = true;
			}

			if (projectList.indexOf('档案（遗传史）') >= 0) {
				this.getHealthData();
			}

			if (projectList.indexOf('人体成分') >= 0) {
				if (readData.indexOf('人体成分数据') >= 0) {
					this.getBodyData();
				} else {
					this.$Message.error('请录入人体成分数据');
				}
			}

			if (
				projectList.indexOf('13+1血压及血管风险管理') >= 0 ||
				projectList.indexOf('5点血糖管理') >= 0
			) {
				reportSportsService
					.getHeadInfo({
						member_id: this.member_id,
					})
					.then((data) => {
						this.setReportUserData(data);
					});
			}

			if (projectList.indexOf('13+1血压及血管风险管理') >= 0) {
				if (readData.indexOf('13+1血压及血管风险管理') >= 0) {
					this.getBloodData(2);
				} else {
					this.$Message.error('请录入13+1血压及血管风险管理数据');
				}
			}

			if (projectList.indexOf('5点血糖管理') >= 0) {
				if (readData.indexOf('5点血糖管理') >= 0) {
					this.getBloodData(1);
				} else {
					this.$Message.error('请录入5点血糖管理数据');
				}
			}

			if (projectList.indexOf('SUN生理评估') >= 0) {
				if (readData.indexOf('PECKB问卷') >= 0) {
					this.getSunData();
				} else {
					this.doubleTip = true;
					this.$Message.error('请完成PECKB问卷');
				}
			}

			if (projectList.indexOf('健康衰退曲线') >= 0) {
				// 防止接口重复调用
				if (readData.indexOf('PECKB问卷') < 0) {
					this.getSunData();
				}
			}
			if (projectList.indexOf('PECKB问卷') >= 0) {
				if (readData.indexOf('PECKB问卷') >= 0) {
					this.getPeckbFn();
				} else {
					if (!this.doubleTip) {
						this.$Message.error('请完成PECKB问卷');
					}
				}
			}
			this.doubleTip = false;
		});
	},
	methods: {
		...mapActions(['setReportUserData']),
		renderTxt(item) {
			return item;
		},
		initFlagData() {
			this.healthFlag = false;
			this.bodyCompositionFlag = false;
			this.sunDataFlag = false;
			this.peckbDataFlag = false;
			this.chartFlag = false;
			this.coverFlag2 = false;
			this.coverFlag1 = false;
			this.bloodPressureFlag = false;
			this.bloodSugarFlag = false;
		},
		getReportList(page = 1) {
			let params = {
				start_time: this.start_time,
				end_time: this.end_time,
				type: 4,
				month_flag: 0,
				pic_type: 45,
				page: page,
				size: 30,
				sid: this.sid,
				cid: this.cid,
				pid: this.pid,
			};
			memberService.getReportList(params).then((data) => {
				this.reportList = data.list;
				this.reportTotal = data.row_size;
				this.reportCurrentPage = +data.cur_page;
			});
		},
		changePageList(page) {
			this.getReportList(page);
		},
		getPeckbFn() {
			let params = {
				member_id: this.member_id,
				get_last: 1,
			};
			riskService.getQuestData(params).then((res) => {
				this.peckbData = res;
				setTimeout(() => {
					this.peckbDataFlag = true;
				});
			});
		},
		getSunData() {
			let params = {
				member_id: this.member_id,
			};
			reportSportsService.getSunData(params).then((res) => {
				this.chartNumber = res.stqx;
				let slfxpg = res.pfzz.slfxpg; // 生理评分数据
				this.reportRes = res.pfzz.slfxpg.bgjg;
				this.healthChratComment = `评价：结合您的生理数据和行为数据综合判断，您的衰退速率相较于同龄人为${res.stpj1}，需${res.stpj2}积极改善您现有健康状况，延缓衰退速率。`;
				let slfxpgData = {
					0: slfxpg.ycxx,
					1: slfxpg.jbxx,
					2: slfxpg.slnl,
				};
				this.slyxys = res.pfzz.slyxys.bgjg; // 生理影响因素的报告内容
				let pgmfArray1 = [res.pgmf[0], res.pgmf[1]]; // 魔方数据
				let pgmfArray2 = [res.pgmf[2]]; // 魔方数据

				pgmfArray1.forEach((item) => {
					let url = `s${item.score[0]}n${item.score[2]}u${item.score[1]}`;
					let imgArrag = [
						's1n1u1',
						's1n1u-1',
						's1n-1u1',
						's1n-1u-1',
						's-1n1u1',
						's-1n1u-1',
						's-1n-1u1',
						's-1n-1u-1',
					];
					item.imgUrl = imgArrag.indexOf(url) >= 0 ? `${url}.png` : `sun_banner.png`;
				});

				pgmfArray2.forEach((item) => {
					let url = `s${item.score[0]}n${item.score[2]}u${item.score[1]}`;
					let imgArrag = [
						's1n1u1',
						's1n1u-1',
						's1n-1u1',
						's1n-1u-1',
						's-1n1u1',
						's-1n1u-1',
						's-1n-1u1',
						's-1n-1u-1',
					];
					item.imgUrl = imgArrag.indexOf(url) >= 0 ? `${url}.png` : `sun_banner.png`;
				});
				this.pgmfArray1 = pgmfArray1;
				this.pgmfArray2 = pgmfArray2;

				if (Array.isArray(res.bodyTag) && res.bodyTag.length > 0) {
					res.bodyTag.forEach((item, index) => {
						this.bodyTag[index].txt = item.value;
						this.bodyTag[index].isShow = item.show;
					});
				}
				this.slyxysSm = res.slyxys.sm; // 生理影响因素排序描述
				this.slyxysPx[0].value = res.slyxys.px[1];
				this.slyxysPx[1].value = res.slyxys.px[0];
				this.slyxysPx[2].value = res.slyxys.px[2];
				this.sunData.forEach((item, index) => {
					item.value = slfxpgData[index];
				});
				if (this.projectList.indexOf('SUN生理评估') >= 0) {
					this.sunDataFlag = true;
				}
				if (this.projectList.indexOf('健康衰退曲线') >= 0) {
					this.chartFlag = true;
				}
			});
		},
		getHealthData() {
			let params = {
				member_id: this.member_id,
			};
			riskService.healthRecords(params).then((res) => {
				this.sickUserInfo = res.jbxx;
				this.healthFlag = true;
				this.family = res.jzycs;
				this.nowSick = res.xyjb;
				this.careSick = res.zgxjb;
			});
		},
		getBodyData() {
			const params = {
				member_id: this.member_id,
			};
			riskService.getBodyComposition(params).then((res) => {
				this.bodyComposition = res;
				this.bodyCompositionFlag = true;
			});
		},
		getBloodData(type) {
			const params = {
				member_id: this.member_id,
				sj_type: type,
			};
			riskService.getBloodData(params).then((data) => {
				if (type === 1) {
					this.bloodSugarFlag = true;
					this.bloodSugarData = data.sj;
				}
				if (type === 2) {
					this.bloodPressureFlag = true;
					this.bloodPressureData = {
						reportResult: {
							bgjg: data.sj.bgjg,
							jcpj: data.sj.jcpj,
						},
						...data.sj,
					};
				}
			});
		},
		getImage(img) {
			return new URL(`/src/assets/img/${img}`, import.meta.url).href;
		},
	},
	components: {
		Health,
		Analysis,
		Peckb,
		HealthChart,
		SunList,
		PressureOne,
		PressureTwo,
		SugarReport,
	},
};
</script>

<style lang="less" scoped>
	.flex {
		display: flex;
	}

	.banner {
		width: 100%;
		height: 1400px;

		img {
			width: 100%;
			height: 100%;
		}
	}

	.marginBottom {
		margin-bottom: 20px;
	}

	.sun_title {
		font-size: 34px;
		margin-top: 10px;
		color: rgb(29, 107, 198);
		line-height: 40px;
		text-align: center;
		position: relative;

		.sun_store {
			position: absolute;
			right: 30px;
			top: 10px;
			font-size: 22px;
		}
	}

	.border_line {
		width: 100%;
		height: 5px;
		background-color: rgb(29, 107, 198);
		margin: 10px 0px;
	}

	.title_tip {
		position: relative;
		align-items: center;
		margin: 20px 30px;

		.left {
			width: 70px;
			height: 70px;
			border-radius: 50%;
			background-color: rgb(29, 107, 198);
			color: #fff;
			position: absolute;
			top: -16px;
			left: 52px;
			align-items: center;
			justify-content: center;

			img {
				width: 60%;
			}
		}

		.right {
			flex: 1;
			height: 35px;
			line-height: 35px;
			margin-left: 100px;
			color: #fff;
			background-color: rgb(29, 107, 198);
			border-radius: 30px;
			padding-left: 30px;
			font-size: 24px;
		}
	}

	.pic_box {
		justify-content: center;
		align-items: center;
		width: 100%;

		.pic_content {
			width: 720px;
			height: 380px;
			position: relative;

			.sun_tag_data {
				font-size: 24px;
				color: #fff;
				position: absolute;
				left: 10vw;
				top: 10vh;
			}

			img {
				width: 100%;
				height: 100%;
			}

			.sun_tag {
				width: 100px;
				position: absolute;
				flex-direction: column;
				align-items: center;

				.sun_tag_img {
					width: 60px;
					height: 60px;
					display: flex;
					justify-content: center;
					align-items: center;

					img {
						width: 60%;
						height: 60%;
					}
				}

				.sun_tag_txt {
					font-size: 20px;
				}

				.sun_tag_txt_eng {
					font-size: 16px;
				}
			}
		}
	}

	.pic_box_body {
		justify-content: center;
		align-items: center;

		.pic_content_body {
			width: 576px;
			height: 440px;
			position: relative;

			.body_tag {
				position: absolute;
				padding: 5px 10px;
				z-index: 9;
				color: #fff;
				justify-content: center;
				align-items: center;
				left: 20vw;
				font-size: 20px;
				top: 10vh;

				span {
					position: relative;
					z-index: 9;
				}

				.right_line {
					right: 0;
				}

				.left_line {
					left: 0;
				}

				.body_tag_line {
					z-index: 8;
					position: absolute;
					width: 214px;
					height: 2px;
					top: 21px;
				}
			}

			img {
				width: 100%;
				height: 100%;
			}
		}
	}

	.report_res {
		width: 200px;
		height: 50px;
		align-items: center;
		justify-content: center;
		background: rgb(29, 107, 198);
		color: #fff;
		margin-left: 40px;
		font-size: 24px;
	}

	.report_res_list {
		padding-left: 40px;
		margin-top: 30px;

		.report_res_item {
			color: rgba(89, 89, 89, 1);
			font-size: 21px;
			font-weight: bolder;
			margin-bottom: 10px;

			.resTag {
				color: red;
			}
		}
	}

	.report_end_res {
		color: rgb(181, 47, 34);
		font-size: 23px;
		padding: 0 30px;
		margin-top: 20px;
		margin-bottom: 20px;
	}

	.system {
		margin-bottom: 0;
	}

	.sub_script {
		width: 100%;
		height: 70px;
		line-height: 70px;
		font-size: 24px;
		color: rgb(29, 107, 198);
		border-bottom: 1px solid rgb(29, 107, 198);
		padding-left: 40px;
		margin-bottom: 15px;
	}

	.height310 {
		height: 310px !important;
	}

	.sun_banner {
		width: 100%;
		height: 360px;
		justify-content: center;
		margin-bottom: 40px;

		.sun_banner_content {
			width: 760px;
			height: 100%;
			position: relative;

			img {
				width: 100%;
				height: 100%;
			}

			.sun_banner_ranktxt {
				position: absolute;
				color: #fff;
				font-size: 16px;
				position: absolute;
				left: 10vw;
				top: 10vh;
			}
		}
	}

	.no_border {
		border-right: 0 !important;
	}

	.cube_res {
		width: 100%;
		justify-content: center;

		.cube_res_detail {
			width: 55vw;
			height: 60px;
			margin-top: 20px;

			.res_tag {
				width: 200px;
				height: 50px;
				align-items: center;
				justify-content: center;
				background: rgb(29, 107, 198);
				color: #fff;
				font-size: 24px;
			}

			.res_table {
				width: 300px;
				margin-left: 100px;

				.res_table_heard {
					color: rgb(29, 107, 198);
					font-size: 18px;
					width: 100%;
					justify-content: center;
					align-items: center;
					border: 1px solid #eaeaea;

					.table_heard_item {
						flex: 1;
						justify-content: center;
						align-items: center;
						border-right: 1px solid #eaeaea;
					}
				}
			}
		}
	}

	.cube_res_tip {
		width: 100%;
		align-items: center;
		justify-content: center;

		.cube_res_tip_ctx {
			width: 55vw;
			font-size: 20px;
			margin-bottom: 20px;
			margin-top: 35px;
			color: #f37231;
		}
	}

	.cube_bottom {
		width: 100%;
		height: 1px;
		background: rgb(29, 107, 198);
		margin-top: 20px;
	}

	.sun_hr {
		width: 100%;
		height: 1px;
		background: rgb(29, 107, 198);
		margin-top: 20px;
		margin-bottom: 20px;
	}

	.rank_title {
		padding-left: 40px;
		font-size: 22px;
		color: rgb(29, 107, 198);
	}

	.rank_list {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 0 40px;

		.rank_item {
			padding: 15px 15px 15px 25px;
			border-radius: 10px;
			margin-bottom: 10px;
			position: relative;
			background-color: #feedec;

			.rank_item_txt {
				// color: #5e5e7e;
				font-size: 19px;
				font-weight: bolder;
			}

			.index_container {
				width: 60px;
				height: 60px;
				border-radius: 50%;
				position: absolute;
				left: -30px;
				top: -30px;
				justify-content: center;
				align-items: center;
				background-color: #fd7c7b;

				.index_center {
					width: 50px;
					height: 50px;
					border-radius: 50%;
					background: #fff;
					font-size: 33px;
					color: #ea8480;
					justify-content: center;
					align-items: center;
				}
			}
		}
	}

	.peckbBox {
		padding-top: 10px;
	}

	.compoentDom {
		background-color: #fff;
	}
</style>
