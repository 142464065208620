<template>
  <div class="search-box">
    <h2 class="search-title border-bottom">按条件搜索</h2>
    <Form class="search-form" :label-width="85">
      <Form-item label="选择对象">
        <Row v-if="!branchShow">
          <Col span="5">
            <span class="sub-label">省份：</span>
            <div class="sub-content">
              <Select
                clearable
                v-model="reportData.pid"
                placeholder="请选择..."
                @on-change="changePro"
              >
                <Option :value="v.id" v-for="v in proList" :key="v.id">{{v.name}}</Option>
              </Select>
            </div>
          </Col>
          <Col span="5" offset="1">
            <span class="sub-label">城市：</span>
            <div class="sub-content">
              <Select
                clearable
                v-model="reportData.cid"
                @on-change="changeCity"
                placeholder="请选择..."
              >
                <Option :value="v.id" v-for="v in cityList" :key="v.id">
                  {{ v.name }}
                </Option>
              </Select>
            </div>
          </Col>
          <Col span="5" offset="1">
            <span class="sub-label">门店：</span>
            <div class="sub-content">
              <Select
                clearable
                v-model="reportData.sid"
                placeholder="请选择..."
                @on-change="changeStore"
              >
                <Option :value="v.id" v-for="v in storeList" :key="v.id">
                  {{ v.name }}
                </Option>
              </Select>
            </div>
          </Col>
          <Col span="5" offset="1">
            <span class="sub-label">会员：</span>
            <div class="sub-content">
              <Select clearable v-model="uid" placeholder="请选择..." filterable>
                <Option :value="v.id" v-for="v in userList" :key="v.id">
                  {{ v.true_name }}
                </Option>
              </Select>
            </div>
          </Col>
        </Row>
        <Row v-else>
          <Col span="5">
            <span class="sub-label">省份：</span>
            <div class="sub-content">
              <Input disabled v-model="proName"></Input>
            </div>
          </Col>
          <Col span="5" offset="1">
            <span class="sub-label">城市：</span>
            <div class="sub-content">
              <Input disabled v-model="cityName"></Input>
            </div>
          </Col>
          <Col span="5" offset="1">
            <span class="sub-label">门店：</span>
            <div class="sub-content">
              <Input disabled v-model="sName"></Input>
            </div>
          </Col>
          <Col span="5" offset="1">
            <span class="sub-label">会员：</span>
            <div class="sub-content">
              <Select clearable v-model="uid" placeholder="请选择..." filterable>
                <Option :value="v.id" v-for="v in userList" :key="v.id">
                  {{ v.true_name }}
                </Option>
              </Select>
            </div>
          </Col>
        </Row>
      </Form-item>
      <Form-item label="日期筛选">
        <Date-picker format="yyyy-MM-dd" type="daterange" placeholder="选择日期" @on-change="handleChangeReportDate" style="width:300px;margin-right:10px;"></Date-picker>
        <Button type="primary" @click="getReportList">搜索报告</Button>
      </Form-item>
      <Form-item label="评估项目">
        <Checkbox :indeterminate="indeterminate" :value="checkAll" @click.prevent.native="handleCheckAll">全选</Checkbox>
      </Form-item>
      <Form-item label="">
        <CheckboxGroup v-model="selectedProjects" @on-change="checkAllGroupChange">
          <Checkbox :label="item" v-for="(item,index) in allProjects" :key='index'></Checkbox>
        </CheckboxGroup>
      </Form-item>
      <Form-item label="准备数据">
        <CheckboxGroup v-model="selectReadData" >
          <Checkbox :label="item" v-for="(item,index) in readData" :key='index' disabled></Checkbox>
        </CheckboxGroup>
      </Form-item>
      <Form-item label="">
        <Button @click="saveReport" style="margin-right: 4px;">生成报告</Button>
        <Button @click="saveAsPDF">存为PDF</Button>
      </Form-item>
    </Form>
  </div>
</template>

<script>
import qualityService from '@/services/qualityService';
import comparisonService from '@/services/comparisonService';
import riskService from '@/services/riskService';
import bus from './eventBus';
export default {
	name: '',
	data() {
		return {
			allProjects: [
				'2021适生健康方案',
				'风险评估方案',
				'档案（遗传史）',
				'人体成分',
				'13+1血压及血管风险管理',
				'5点血糖管理',
				'SUN生理评估',
				'PECKB问卷',
				'健康衰退曲线',
			],
			selectedProjects: [],
			readData: [
				'档案（遗传史）',
				'PECKB问卷',
				'人体成分数据',
				'13+1血压及血管风险管理',
				'5点血糖管理',
			],
			selectReadData: [],
			chooseAll: '',
			seleteProject: [],
			indeterminate: false,
			checkAll: false,
			proList: [],
			branchShow: true,
			reportList: [],
			cityList: [],
			userList: [],
			storeList: [],
			branch_id: [],
			reportTotal: 0,
			uid: '',
			reportCurrentPage: 1,
			power: [],
			proName: '',
			cityName: '',
			sName: '',
			reportData: {
				type: 1,
				month_flag: 0,
				pic_type: 17,
				page: 1,
				size: 30,
				pid: '',
				cid: '',
				sid: '',
			},
			sickUserInfo: {},
			family: {},
			nowSick: '',
			careSick: '',
			BodyComposition: {},
			healthFlag: false,
			BodyCompositionFlag: false,
			start_time: '',
			end_time: '',
		};
	},
	created() {
		let list = JSON.parse(localStorage.getItem('userInfo'));
		qualityService.getQualitySelect().then((data) => {
			this.power = list.user_info.power;
			if (this.power.indexOf('会员管理--查看所属店面会员信息权限') === -1) {
				this.branchShow = false;
			} else {
				this.branch_id = list.user_info.branch;
				this.reportData.sid = this.branch_id[2];
				this.reportData.cid = this.branch_id[1];
				this.reportData.pid = this.branch_id[0];
				this.proName = list.user_info.pro_name;
				this.cityName = list.user_info.city_name;
				this.sName = list.user_info.branch_name;
				this.changeStore();
			}
			this.proList = data.branch.pro;
			this.city = data.branch.city;
			this.store = data.branch.store;
		});
	},
	mounted() {},
	computed: {},
	watch: {
		uid(newValue) {
			if (newValue) {
				let params = { member_id: newValue };
				riskService.getReadData(params).then((res) => {
					this.selectReadData = [];
					res.forEach((item, index) => {
						if (item == 1) {
							this.selectReadData.push(this.readData[index]);
						}
					});
					bus.$emit('changeUid', { member_id: newValue, selectReadData: this.selectReadData });
				});
			}
		},
	},
	methods: {
		// 选择日期
		handleChangeReportDate(value) {
			this.start_time = value[0];
			this.end_time = value[1];
		},
		// 搜索报告
		getReportList() {
			let obj = {
				start_time: this.start_time,
				end_time: this.end_time,
				sid: this.reportData.sid,
				cid: this.reportData.cid,
				pid: this.reportData.pid,
			};
			bus.$emit('getReportList', obj);
		},
		// 根据省份选择城市
		changePro() {
			this.cityList = this.city[this.reportData.pid];
			this.reportData.cid = '';
		},
		// 根据城市选择门店
		changeCity() {
			this.storeList = this.store[this.reportData.cid];
			this.reportData.sid = '';
		},
		// 根据门店选择用户
		changeStore() {
			this.uid = '';
			if (this.reportData.sid) {
				const params = {
					page: 1,
					size: 1000,
					pid: this.reportData.pid,
					cid: this.reportData.cid,
					sid: this.reportData.sid,
					simple_query: 1,
				};
				comparisonService.getUserList(params).then((data) => {
					this.userList = data.list;
				});
			} else {
				this.userList = [];
			}
		},
		// 生成报告
		saveReport() {
			if (!this.uid) {
				this.$Message.error('请至少选择一位客户');
				return;
			}
			if (this.selectedProjects.length === 0) {
				this.$Message.error('请至少选择一个项目');
				return;
			}
			bus.$emit('saveReport', this.selectedProjects);
		},
		// 存为pdf
		saveAsPDF() {
			this.$emit('saveAsPDF');
		},
		// 选择项目
		handleCheckAll() {
			if (this.indeterminate) {
				this.checkAll = false;
			} else {
				this.checkAll = !this.checkAll;
			}
			this.indeterminate = false;
			if (this.checkAll) {
				this.selectedProjects = this.allProjects;
			} else {
				this.selectedProjects = [];
			}
		},
		checkAllGroupChange(data) {
			if (data.length === 7) {
				this.indeterminate = false;
				this.checkAll = true;
			} else if (data.length > 0) {
				this.indeterminate = true;
				this.checkAll = false;
			} else {
				this.indeterminate = false;
				this.checkAll = false;
			}
		},
	},
	components: {},
};
</script>

<style scoped lang='less'>
.sub-label {
  vertical-align: middle;
  float: left;
}

.sub-content {
  margin-left: 50px;
}
.buttonBox {
  padding: 0 20px 20px 20px;
  button {
    margin-right: 10px;
  }
}
.table {
  width: 100%;
  margin: 10px 0;
  table-layout: fixed;
  text-align: center;
  th {
    background-color: #0062c3;
    color: #fff;
    padding: 5px;
    border: 1px solid #ccc;
  }
  td {
    color: #0062c3;
    padding: 5px;
    height: 40px;
    font-weight: bold;
    border: 1px solid #eee;
  }
}

.report-body {
  margin: 10px;
}

.flex{
  display: flex;
}
</style>
