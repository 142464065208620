<template>
  <div>
    <sunHeard  @saveAsPDF="saveAsPDF" ref='sunHeard'/>
    <div class="content-box mb20">
      <h2 class="content-title border-bottom" v-if="showTitle">SUN生理评估</h2>
      <!--
      <div class="breadcrumbs-bar">
        <span
          :class="['breadcrumb', { active: i === selectedIndex }]"
          v-for="(v, i) in tabList"
          :key="i"
          @click="clickBreadcrumb(i)"
        >{{ v }}</span>
      </div>
      -->
    </div>
    <sunMain ref='sunMain'/>
  </div>
</template>

<script>
import JsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import sunHeard from './heard.vue';
import sunMain from './main.vue';
import dayjs from 'dayjs';
import memberService from '@/services/memberService';
import { Loading } from 'element-ui';
export default {
	name: '',
	data() {
		return {
			selectedIndex: 0,
			tabList: ['封面', '核心健康档案', 'SUN评分总值', 'SUN风险评估魔方', 'SUN风险权重排序'],
			showTitle: false,
		};
	},
	created() {},
	mounted() {
		this.showTitle = this.$refs.sunMain._data.healthFlag;
	},
	computed: {},
	watch: {},
	methods: {
		// 存为pdf
		saveAsPDF() {
			let loadinginstace = Loading.service({ fullscreen: true });
			const printTarget = document.querySelectorAll('.compoentDom');
			if (printTarget.length > 0) {
				const self = this;
				const pdf = new JsPDF({ compress: true, unit: 'pt' });
				Promise.all(
					Array.from(printTarget).map((item) =>
						html2canvas(item, {
							scale: 1,
							scrollX: 0,
							scrollY: -window.scrollY,
						}),
					),
				).then((canvasList) => {
					for (let i = 0; i < canvasList.length; i++) {
						const canvas = canvasList[i];
						const contentWidth = canvas.width;
						const contentHeight = canvas.height;
						const imgWidth = 555.28;
						const imgHeight = (imgWidth / contentWidth) * contentHeight;
						const pageData = canvas.toDataURL('image/png', 1.0);
						if (i !== 0) pdf.addPage();
						pdf.addImage(pageData, 'png', 20, 0, imgWidth, imgHeight);
						if (i === printTarget.length - 1) {
							const time = dayjs().format('YYYYMMDD');
							let memberList = self.$refs.sunHeard.userList;
							let uid = self.$refs.sunHeard.uid;
							let nameArray = memberList.filter((item) => {
								return item.id == uid;
							});
							const fileName = `${nameArray[0].true_name}${time}SUN风险评估方案.pdf`;
							const formData = new FormData();
							formData.append('photo', pdf.output('blob'));
							formData.append('check', 1);
							formData.append('name', fileName);
							memberService.uploadReport(formData).then((data) => {
								memberService
									.addReport({
										member_id: uid,
										type: 4,
										bgzl: 45,
										check_date: data.check_date,
										photo_id: data.id,
										bgms: '出具报告自动上传',
									})
									.then(() => {
										self.$Message.success('转存成功');
									})
									.catch(() => {
										self.$Message.success('转存失败，请手动上传');
									});
							});
							self.$nextTick(() => {
								pdf.save(`${nameArray[0].true_name}SUN风险评估方案.pdf`);
								loadinginstace.close();
							});
						}
					}
				});
			} else {
				loadinginstace.close();
				this.$Message.warning('无法生成PDF，请检查数据是否存在');
			}
		},
		// 点击 breadcrumb
		clickBreadcrumb(index) {
			this.selectedIndex = index;
			const doc = document.documentElement;
			let scrollTop = doc.scrollTop;
			const anchor = document.querySelectorAll('.compoentDom')[index].offsetTop - 100;
			const scroll = function () {
				scrollTop = scrollTop + (anchor - scrollTop) / 4;
				if (Math.abs(scrollTop - anchor) < 1) {
					doc.scrollTop = anchor;
					return;
				}
				doc.scrollTop = scrollTop;
				requestAnimationFrame(scroll);
			};
			scroll();
		},
	},
	components: { sunHeard, sunMain },
};
</script>

<style scoped lang='less'>
.breadcrumbs-bar {
  display: flex;
  border-bottom: #eee solid 1px;
  position: sticky;
  top: 23px;
  background-color: #fff;
  z-index: 10;
  .breadcrumb {
    flex: 1;
    text-align: center;
    padding: 10px 0;
    font-size: 16px;
    cursor: pointer;
    &:hover {
      color: #fdad00;
    }
    &.active {
      color: #fdad00;
    }
  }
}
</style>
