<template>
  <div class="healthWrap">
    <section class="userInfo">
      <div class="healtTitle">
        <div class="healtTitleCtx">健康管理方案</div>
      </div>
      <div class="tag">基本信息</div>
      <div class="userWrap">
        <div class="userDetail">
          <div class="userKey">
            <div class="typeKeyItem" v-for="(item,index) in userInforA" :key='index'>{{item}}</div>
          </div>
          <div class="userValue">
            <div class="userValueItem">{{sickUserInfo.name}}</div>
            <div class="userValueItem">{{sickUserInfo.sex_show}}</div>
            <div class="userValueItem onBorder">{{sickUserInfo.birthday}}</div>
          </div>
          <div class="userKey">
            <div class="typeKeyItem" v-for="(item,index) in userInforB" :key='index'>{{item}}</div>
          </div>
          <div class="userValue">
            <div class="userValueItem">{{sickUserInfo.age}}</div>
            <div class="userValueItem">{{sickUserInfo.md}}</div>
            <div class="userValueItem onBorder">{{sickUserInfo.mobile}}</div>
          </div>
        </div>
        <div class="userImg">
          <img :src="sickUserInfo.head" alt="" v-if="sickUserInfo.head">
          <img src="@/assets/img/defaultUser.jpg" v-else  alt="">
        </div>
      </div>
    </section>
    <section class="seHr"></section>
    <section class="inheriWrap">
      <div class="inheritTag">家族遗传史</div>
      <div class="inheritCtx">
        <div class="inheritCtxLeft">
          <div class="inheritCtxLeftKey">
            <div class="LeftKeyItem" v-for="(item,index) in familyArray" :key='index'>{{item}}</div>
          </div>
        </div>
        <div class="inheritCtxRight">
          <div class="inheritCtxLeftValue">
            <div class="LeftKeyItem">:{{family.father}}</div>
            <div class="LeftKeyItem">:{{family.mother}}</div>
            <div class="LeftKeyItem">:{{family.brother}}</div>
          </div>
        </div>
      </div>
    </section>
    <section class="nowSick">
      <div class="sickTag">现有疾病</div>
      <div class="sickCtx">
        <div>{{nowSick}}</div>
        <div class="careSick">最关心疾病</div>
        <div class="careSickList">
          <div class="careSickItem">{{careSick}}</div>
        </div>
      </div>
      <div class="sign">
        <div class="signBox">
          <div class="signL">风险评估方案</div>
          <div class="signLine"></div>
          <div class="signCompany">康一生核心健康档案</div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
	name: '',
	props: {
		sickUserInfo: {
			type: Object,
			default: () => {
				return {};
			},
		},
		family: {
			type: Object,
			default: () => {
				return {};
			},
		},
		nowSick: {
			type: String,
			default: () => {
				return '';
			},
		},
		careSick: {
			type: String,
			default: () => {
				return '';
			},
		},
	},
	data() {
		return {
			userInforA: ['姓名', '性别', '生日'],
			userInforB: ['年龄', '门店', '手机号'],
			familyArray: ['父亲', '母亲', '兄弟姐妹'],
		};
	},
	mounted() {},
	computed: {},
	watch: {},
	methods: {},
	components: {},
};
</script>

<style scoped lang='less'>
.healthWrap {
  padding: 0 20px 20px 20px;
  position: relative;
  .userInfo {
    margin-bottom: 30px;
    .healtTitle {
      // padding-bottom:20px;
      padding-top: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid #1c6193;
      .healtTitleCtx {
        font-size: 22px;
        color: #1c6193;
        height: 40px;
        font-weight: bolder;
      }
    }
    .tag {
      width: 200px;
      padding: 4px 25px;
      background-color: #f5ad4f;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-size: 24px;
      border-radius: 6px;
      margin-bottom: 30px;
    }
    .userWrap {
      display: flex;
      justify-content: space-between;
      .userDetail {
        width: 60%;
        height: 200px;
        border: 1px solid #6a92c2;
        border-radius: 10px;
        display: flex;
        .userKey {
          display: flex;
          flex-direction: column;
          background-color: #6a92c2;
          width: 15%;
          height: 100%;
          .typeKeyItem {
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            color: #fff;
          }
        }
        .userValue {
          width: 35%;
          height: 100%;
          display: flex;
          flex-direction: column;
          padding: 0 2%;
          .userValueItem {
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            color: #101010;
            border-bottom: 1px solid #6f96c4;
          }
          .onBorder {
            border-bottom: 0;
          }
        }
      }
      .userImg {
        width: 28%;
        height: 300px;
        position: absolute;
        top: 60px;
        right: 20px;
        img{
        width: 100%;
        height: 100%;
        }
      }
    }
  }
  .inheriWrap {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
    .inheritTag {
      width: 200px;
      padding: 4px 25px;
      background-color: #f5ad4f;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-size: 24px;
      border-radius: 6px;
      margin-bottom: 30px;
    }
    .inheritCtx {
      width: 100%;
      background-color: #e3eef1;
      display: flex;
      font-size: 24px;
      padding: 20px;
      .inheritCtxLeft {
        width: 16%;
        height: 100%;
        margin-right: 4%;
        .LeftKeyItem {
          width: 100%;
          height: 30px;
          display: flex;
          justify-content: flex-end;
          margin-bottom: 20px;
        }
      }
      .inheritCtxRight {
        width: 80%;
        height: 100%;
        .LeftKeyItem {
          width: 100%;
          height: 30px;
          margin-bottom: 20px;
        }
      }
    }
  }
  .nowSick {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
    .sickTag {
      width: 200px;
      padding: 4px 25px;
      background-color: #f5ad4f;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      border-radius: 6px;
      margin-bottom: 30px;
    }
    .sickCtx {
      width: 100%;
      background-color: #e3eef1;
      display: flex;
      font-size: 24px;
      padding: 20px;
      margin-bottom: 20px;
      flex-direction: column;
    }
    .sign {
      margin-top: 30px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .signBox{
        display: flex;
        align-items: center;
      }
      .signL {
        padding: 5px 10px;
        background-color: #979797;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
      }
      .signLine {
        width: 70px;
        margin: 0 10px;
        height: 2px;
        background-color: #c9c9c9;
      }
      .signCompany {
        font-size: 18px;
      }
    }
    .careSick {
      padding: 4px 25px;
      width: 150px;
      background-color: #f5ad4f;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      border-radius: 6px;
      margin-bottom: 30px;
      margin-top: 30px;
    }
    .careSickList {
      width: 100%;
      flex-wrap: wrap;
      display: flex;
    }
  }
}
</style>
