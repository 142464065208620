<template>
  <div class="content-box mb20">
    <h2 class="content-title border-bottom">SUN风险评估方案记录</h2>
    <div class="report-body">
      <Table :columns="reportColumns" :data="reportList" :height="220"></Table>
      <div>
        <span class="records">共 {{ reportTotal }} 条记录</span>
      </div>
      <div class="page-nations-wrapper">
        <div class="page-nations">
          <Page
            :total="reportTotal"
            :current="reportCurrentPage"
            :page-size="reportData.size"
            @on-change="changeReportPage"
          ></Page>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
	name: '',
	props: {
		reportList: Array,
		reportTotal: Number,
		reportData: Object,
		reportCurrentPage: Number,
	},
	data() {
		return {
			reportColumns: [
				{
					title: '门店',
					key: 'md',
					align: 'center',
				},
				{
					title: '顾客姓名',
					key: 'gkmc',
					align: 'center',
				},
				{
					title: '报告名称',
					key: 'bgmc',
					align: 'center',
				},
				{
					title: '出具日期',
					key: 'cjrq',
					align: 'center',
				},
				{
					title: '检测师',
					key: 'jcs',
					align: 'center',
				},
				{
					title: '操作',
					key: 'action',
					width: 150,
					align: 'center',
					render: (h, params) => {
						const url = params.row.url;
						return h('div', [
							h('a', {
								class: 'icon_eye',
								attrs: {
									href: url,
									target: '_blank',
								},
							}),
							h('Icon', {
								attrs: {
									type: 'md-arrow-round-down',
									size: 22,
									color: '#fdad00',
								},
								style: {
									cursor: 'pointer',
								},
								on: {
									click: () => {
										const a = document.createElement('a');
										a.href = url;
										a.style.display = 'none';
										a.setAttribute('download', decodeURI(url.substring(url.lastIndexOf('/') + 1)));
										document.body.appendChild(a);
										a.click();
										document.body.removeChild(a);
									},
								},
							}),
						]);
					},
				},
			],
		};
	},
	methods: {
		changeReportPage(page) {
			this.$emit('changePage', page);
		},
	},
};
</script>

<style scoped lang='less'>
.report-body {
  margin: 10px;
}

.mb20 {
  margin-bottom: 20px;
}
</style>
